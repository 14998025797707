<template class="mr-3">
  <div class="card">
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :width="400"
      :footer="false"
    >
      <a-form :form="rejectForm" @submit="rejectPunition">
        <a-form-item :label="$t('punition.raison')">
          <a-textarea
            :placeholder="$t('punition.rejectReason')"
            v-decorator="[
              'reason',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('punition.reasonRequired'),
                  },
                ],
              },
            ]"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>

        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            html-type="submit"
            class="mr-3"
            >{{ $t("action.confirmer") }}</a-button
          >
          <a-button key="back" @click="cancelReject">{{
            $t("action.annuler")
          }}</a-button>
        </div>
      </a-form>
    </a-modal>
    <a-modal
      v-model="addPunishmentModal"
      @cancel="resetAndCloseAddPunishment"
      :title="$t('all.add') + ' ' + $t('punition.punition')"
      :width="800"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-form :form="punishmentForm" @submit="addPunition">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <a-form-item :label="$t('emploi.classe')">
                <a-select
                  @change="handleChangeClass"
                  show-search
                  :filter-option="filterOption"
                >
                  <a-select-option
                    v-for="classRoom in classList"
                    :key="classRoom._id"
                    :value="classRoom._id"
                  >
                    {{ classRoom.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item :label="$t('punition.eleve')">
                <a-select
                  :disabled="studentList.length == 0"
                  :loading="classRoomChangeLoading"
                  :value="selectedStudents"
                  show-search
                  allow-clear
                  mode="multiple"
                  :filter-option="filterOption"
                  @change="selectStudents"
                >
                  <a-select-option
                    v-for="student in studentList"
                    :key="student._id"
                    :value="student._id"
                  >
                    {{ student.firstName }} {{ student.lastName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
          </div>
          <div class="row" v-if="selectedStudents.length > 0">
            <div class="card col-md-12">
              <div class="card-body container">
                <div class="row">
                  <div class="col-md-12">
                    <a-form-item :label="$t('punition.raison')">
                      <a-input
                        v-decorator="[
                          'reason',
                          {
                            rules: [
                              {
                                required: true,
                                message: this.$t('punition.reasonRequired'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <a-form-item :label="$t('recette.type')">
                      <a-select
                        v-decorator="[
                          'punishmentType',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Le type est requis',
                              },
                            ],
                          },
                        ]"
                        show-search
                        :filter-option="filterOption"
                      >
                        <a-select-option
                          v-for="punishmentType in punishmentTypes"
                          :key="punishmentType._id"
                          :value="punishmentType._id"
                        >
                          {{ punishmentType.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-md-6">
                    <a-form-item label="temps de l'événement">
                      <a-date-picker
                        show-time
                        :placeholder="$t('recette.selectionnerDate')"
                        v-decorator="[
                          'time',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Le temp est requis',
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <a-form-item :label="$t('actualite.description')">
                      <a-textarea
                        :placeholder="$t('actualite.description')"
                        v-decorator="[
                          'desc',
                          {
                            rules: [
                              {
                                required: false,
                                message: this.$t('punition.reasonRequired'),
                              },
                            ],
                          },
                        ]"
                        :auto-size="{ minRows: 3, maxRows: 5 }"
                      />
                    </a-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            html-type="submit"
            class="mr-3"
            >{{ $t("action.confirmer") }}</a-button
          >
          <a-button key="back" @click="resetAndCloseAddPunishment">{{
            $t("action.annuler")
          }}</a-button>
        </div>
      </a-form>
    </a-modal>
    <div class="card-body">
      <div>
        <div>
          <!-- Profile MODAL --->
          <a-drawer
            :title="$t('punition.punition')"
            placement="right"
            :closable="true"
            :visible="visibleProfileData"
            @close="handleCancel"
            :width="600"
          >
            <a-form class="container" style="font-size: 15px">
              <fieldset>
                <legend>{{ $t("punition.eleve") }}:</legend>
                <div class="row">
                  <div class="col-md-3">
                    <strong class="pr-1"> {{ $t("punition.nom") }}: </strong>
                    {{
                      updateData.student ? updateData.student.firstName : null
                    }}
                  </div>
                  <div class="col-md-3">
                    <strong class="pr-1"> {{ $t("punition.prenom") }}: </strong>
                    {{
                      updateData.student ? updateData.student.lastName : null
                    }}
                  </div>
                </div>
              </fieldset>
              <fieldset class="mt-3">
                <legend>{{ $t("punition.details") }}:</legend>
                <div class="row">
                  <div class="col-md-12">
                    <strong class="pr-1">
                      {{ $t("punition.punition") }}: </strong
                    >{{
                      updateData.punishmentType
                        ? updateData.punishmentType.name
                        : null
                    }}
                  </div>
                  <div class="col-md-12">
                    <strong class="pr-1">
                      {{ $t("punition.dateAjout") }}: </strong
                    >{{ moment(updateData.time).format("DD/MM/YYYY HH:mm") }}
                  </div>
                  <div class="col-md-12">
                    <strong class="pr-1">
                      {{ $t("punition.dateEvalution") }}: </strong
                    >{{
                      moment(updateData.reviewDate).format("DD/MM/YYYY HH:mm")
                    }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <strong class="pr-1"> {{ $t("punition.raison") }}: </strong
                    >{{ updateData.reason }}
                  </div>
                  <div class="col-md-12">
                    <strong> {{ $t("punition.description") }}: </strong>
                    <br />
                    {{ updateData.desc }}
                  </div>
                </div>
                <div class="row" v-if="updateData.rejectionReason">
                  <div class="col-md-12">
                    <strong class="pr-1">
                      {{ $t("punition.rejectReason") }}: </strong
                    >{{ updateData.rejectionReason }}
                  </div>
                </div>
              </fieldset>
              <div
                :style="{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  borderTop: '1px solid #e9e9e9',
                  padding: '10px 16px',
                  background: '#fff',
                  textAlign: 'right',
                  zIndex: 1,
                }"
              >
                <a-button-group style="width: 100%">
                  <a-button
                    style="width: 100%"
                    :disabled="updateData.validated === 'validated'"
                    type="primary"
                    @click.stop="validation(updateData._id, 'validated')"
                  >
                    <a-icon type="check" />{{ $t("punition.valider") }}
                  </a-button>
                  <a-button
                    style="width: 100%"
                    :disabled="updateData.validated === 'rejected'"
                    type="danger"
                    @click.stop="
                      () => {
                        visibleProfileData = false;
                        openReasonModal(updateData._id);
                      }
                    "
                  >
                    {{ $t("punition.rejeter") }}<a-icon type="right" />
                  </a-button>
                </a-button-group>
              </div>
            </a-form>
          </a-drawer>
        </div>
      </div>
      <div>
        <a-page-header :title="$t('menu.listePunition')">
          <template slot="extra">
            <a-button type="primary" key="2" @click="showAddPunishment">
              <a-icon type="user-add" />{{ $t("all.add") }}
              {{ $t("punition.punition") }}
            </a-button>
          </template>
          <a-row type="flex">
            <a-statistic
              :title="$t('liste.total')"
              :value="punishmentStats.all"
            />
            <a-statistic
              :title="$t('punition.nonEvalues')"
              prefix="%"
              :value="
                punishmentStats.all != 0
                  ? (
                      (punishmentStats.pending / punishmentStats.all) *
                      100
                    ).toFixed(2)
                  : 0
              "
              :style="{
                margin: '0 32px',
              }"
            />
            <a-statistic
              :title="$t('punition.valides')"
              prefix="%"
              :value="
                punishmentStats.all != 0
                  ? (
                      (punishmentStats.accepted / punishmentStats.all) *
                      100
                    ).toFixed(2)
                  : 0
              "
              :style="{
                margin: '0 32px',
              }"
            />
            <a-statistic
              :title="$t('punition.rejetes')"
              prefix="%"
              :value="
                punishmentStats.all != 0
                  ? (
                      (punishmentStats.rejected / punishmentStats.all) *
                      100
                    ).toFixed(2)
                  : 0
              "
            />
          </a-row>
        </a-page-header>
        <a-table
          :pagination="true"
          :data-source="data"
          :loading="tableLoading"
          :columns="columns"
          :customRow="
            (record) => {
              return {
                on: {
                  click: () => {
                    profile(record);
                  },
                },
              };
            }
          "
          rowKey="_id"
        >
          <template slot="name" slot-scope="text, record">
            <div
              key="firstName"
              @click.stop="() => openProfile(record.student._id)"
            >
              <a-avatar
                size="large"
                v-if="record.student.photo"
                :src="settings.base_url + '/' + record.photo"
              />
              <a-avatar
                size="large"
                style="color: #f56a00; background-color: #fde3cf"
                v-else
              >
                {{ record.student.firstName[0].toUpperCase() }}
              </a-avatar>
              <a-button size="small" type="link" class="ml-2">
                {{ record.student.lastName + " " + record.student.firstName }}
              </a-button>
            </div>
          </template>
          <template slot="reason" slot-scope="text">
            <span v-if="text.length < 17">{{ text }}</span>
            <span v-else>{{ text.slice(0, 14) }} ...</span>
          </template>
          <template slot="createdby" slot-scope="text, record">
            <div class="d-flex">
              <div>{{ record.createdby.fullName }}</div>
              <div class="ml-auto">
                <a-tag :color="record.createdby.color">{{
                  $t(record.createdby.type)
                }}</a-tag>
              </div>
            </div>
          </template>

          <template slot="operation" slot-scope="text, record">
            <div class="editable-row-operations">
              <span v-if="record.editable">
                <a @click="() => save(record.key)">{{ $t("all.save") }}</a>
                <a-popconfirm
                  title="Sure to cancel?"
                  @confirm="() => cancel(record.key)"
                >
                  <a>{{ $t("action.annuler") }}</a>
                </a-popconfirm>
              </span>
              <span v-else>
                <a-button
                  type="primary"
                  :disabled="editingKey !== ''"
                  @click.stop="() => edit(record)"
                >
                  <a-icon
                    type="edit"
                    :disabled="editingKey !== ''"
                    @click.stop="() => edit(record)"
                  />{{ $t("action.modifier") }}
                </a-button>
              </span>
            </div>
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="$t('paiement.chercher') + ` ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="time" slot-scope="text">
            {{ moment(text).format("DD/MM/YYYY HH:mm") }}
          </template>
          <template slot="reviewDate" slot-scope="text">
            <span v-if="text">
              {{ moment(text).format("DD/MM/YYYY HH:mm") }}
            </span>
            <span v-else>--</span>
          </template>
          <template slot="status" slot-scope="text">
            <a-tag color="green" v-if="text === 'validated'">
              {{ $t("punition.valides") }}
            </a-tag>
            <a-tag color="cyan" v-else-if="text === 'notValidated'">
              {{ $t("punition.nonEvalues") }}
            </a-tag>
            <a-tag color="red" v-else> {{ $t("punition.rejetes") }} </a-tag>
          </template>
          <template slot="validation" slot-scope="text, record">
            <a-button-group>
              <a-button
                :disabled="text === 'validated'"
                type="primary"
                @click.stop="validation(record._id, 'validated')"
              >
                <a-icon type="check" />{{ $t("punition.valider") }}
              </a-button>
              <a-button
                :disabled="text === 'rejected'"
                type="danger"
                @click.stop="openReasonModal(record._id)"
              >
                {{ $t("punition.rejeter") }}<a-icon type="right" />
              </a-button>
            </a-button-group>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import "jspdf-autotable";
import { mapState } from "vuex";
import moment from "moment";
import router from "@/router";
const isTeacher = (teacher) => {
  return Array.isArray(teacher.role) && teacher.role.includes("teacher");
};

export default {
  computed: mapState(["user", "settings"]),
  async created() {
    this.tableLoading = true;
    await this.findPunishment();
    apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
          },
        },
      })
      .then((res) => (this.classList = res.data))
      .catch((e) => this.$message.error("Aucunne classe est trouvé"));
    apiClient
      .post("/punishmentType/filter", {
        query: {},
      })
      .then((res) => (this.punishmentTypes = res.data))
      .catch((e) =>
        this.$message.error("Aucunne types de punition est trouvé")
      );
    this.cacheData = this.data.map((item) => ({ ...item }));

    this.tableLoading = false;
  },
  data() {
    return {
      classRoomChangeLoading: false,
      studentList: [],
      classList: [],
      selectedStudents: [],
      punishmentTypes: [],
      punishmentStats: {
        all: 0,
        pending: 0,
        accepted: 0,
        rejected: 0,
      },
      positionsList: [],
      subjectsList: [],
      dateFormat: "DD/MM/YYYY",
      editingKey: "",
      tableLoading: false,
      visibleParentFile: false,
      selectedPunishment: null,
      visibleModal: false,
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      previewVisible: false,
      previewImage: "",
      form: this.$form.createForm(this),
      updateForm: this.$form.createForm(this),
      rejectForm: this.$form.createForm(this),
      punishmentForm: this.$form.createForm(this),
      updateData: {
        student: {
          firstName: "",
          lastName: "",
        },
        punishmentType: {
          _id: "",
          name: "",
        },
        reason: "",
        desc: "",
        desc: "",
        _id: "",
      },
      value: 1,
      value1: 1,
      loadingEnseignant: false,
      visibleData: false,
      visibleUpdateData: false,
      visibleProfileData: false,
      addPunishmentModal: false,
      columns: [
        {
          title: this.$t("liste.nomPrenom"),
          dataIndex: "firstName",
          key: "firstName",
          width: 250,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
          onFilter: (value, record) =>
            record.student.firstName
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            record.student.lastName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.type"),
          dataIndex: "punishmentType.name",
          key: "punishmentType",

          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "punishmentType.name",
          },
          onFilter: (value, record) =>
            record.punishmentType.name
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("topBar.status"),
          dataIndex: "validated",
          key: "status",

          scopedSlots: {
            customRender: "status",
          },
          filters: [
            {
              text: this.$t("punition.valides"),
              value: "validated",
            },
            {
              text: this.$t("punition.rejetes"),
              value: "rejected",
            },
            {
              text: this.$t("punition.nonEvalues"),
              value: "notValidated",
            },
          ],
          onFilter: (value, record) => record.validated.indexOf(value) === 0,
        },
        {
          title: this.$t("punition.dateAjout"),
          dataIndex: "time",
          key: "time",

          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "time",
          },
          onFilter: (value, record) =>
            record.time.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("punition.dateEvalution"),
          dataIndex: "reviewDate",
          key: "reviewDate",

          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "reviewDate",
          },
          onFilter: (value, record) =>
            record.reviewDate.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("punition.raison"),
          dataIndex: "reason",
          key: "reason",
          width: 120,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "reason",
          },
          onFilter: (value, record) =>
            record.reason.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("punition.createdBy"),
          dataIndex: "createdby",
          key: "createdby",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "createdby",
          },
          onFilter: (value, record) =>
            record.createdby.fullName
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("punition.validation"),
          dataIndex: "validated",
          scopedSlots: { customRender: "validation" },
          fixed: "right",
        },
      ],
    };
  },
  methods: {
    async findPunishment() {
      await apiClient
        .post("/studentPunishment/filter", {
          query: {},
        })
        .then((res) => {
          this.punishmentStats = {
            all: 0,
            pending: 0,
            accepted: 0,
            rejected: 0,
          };
          this.data = res.data.map((elem) => {
            if (elem.validated == "validated") this.punishmentStats.accepted++;
            else {
              if (elem.validated == "notValidated")
                this.punishmentStats.pending++;
              else this.punishmentStats.rejected++;
            }
            elem.key = elem._id;
            const teacher = elem.teacher?.employee;
            elem.createdby = {
              fullName: elem.admin
                ? elem.admin.fullName + " " + elem.admin.userName
                : teacher.firstName + " " + teacher.lastName,
              color: elem.admin
                ? "purple"
                : isTeacher(elem.teacher)
                ? "blue"
                : "orange",
              type: elem.admin ? "autorisation.admin" : "emploi.enseignant",
            };
            return elem;
          });
          this.punishmentStats.all =
            this.punishmentStats.accepted +
            this.punishmentStats.rejected +
            this.punishmentStats.pending;
        })
        .catch(() => {});
    },
    moment,
    resetAndCloseAddPunishment() {
      this.punishmentForm.resetFields();
      this.studentList = [];
      this.selectedStudents = [];
      this.classRoomChangeLoading = false;
      this.addPunishmentModal = false;
    },
    handleChangeClass(selectedClassRoom) {
      this.classRoomChangeLoading = true;
      this.selectedStudents = [];
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] =
        selectedClassRoom;
      apiClient
        .post("/students/filter", {
          query: {
            status: "active",
          },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $set: {
                classRoomName: "$classRoom",
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classRoomName",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
              },
            },
          ],
        })
        .then((res) => {
          const { data } = res;
          this.studentList = data;
          this.classRoomChangeLoading = false;
        })
        .catch((e) => this.$message.error(this.$t("error.aucEleve")));
    },
    selectStudents(students) {
      this.selectedStudents = students;
    },
    showAddPunishment() {
      this.addPunishmentModal = true;
    },
    openProfile(id) {
      router.push(`/eleve/${id}`);
    },
    profile(record) {
      this.updateData = record;
      this.visibleProfileData = true;
    },
    handleCancel(e = null) {
      this.form.resetFields();
      this.updateForm.resetFields();
      this.rejectForm.resetFields();
      this.visibleData = false;
      this.visibleUpdateData = false;
      this.visibleProfileData = false;
    },
    addPunition(e) {
      e.preventDefault();
      this.punishmentForm.validateFields((err, values) => {
        if (!err) {
          if (!values.desc) delete values.desc;
          apiClient
            .put("/studentPunishment/admin", {
              ...values,
              students: this.selectedStudents,
            })
            .then(async (res) => {
              await this.findPunishment();
            })
            .catch((e) =>
              this.$notification.error({
                message: this.$t("error.error"),
              })
            )
            .finally(() => this.resetAndCloseAddPunishment());
        }
      });
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    showModal() {
      this.visibleData = true;
    },
    onChange(e) {},
    onChangeParent(e) {},
    openReasonModal(id) {
      this.selectedPunishment = id;
      this.visibleModal = true;
    },
    cancelReject() {
      this.rejectForm.resetFields();
      this.visibleModal = false;
    },
    rejectPunition(e) {
      e.preventDefault();
      this.rejectForm.validateFields((err, values) => {
        if (!err)
          this.validation(this.selectedPunishment, "rejected", values.reason);
      });
    },
    validation(id, value, reason = "") {
      console.info("here: ", value);
      let date = moment();
      apiClient
        .patch("/studentPunishment/" + id, {
          validated: value,
          reviewDate: date,
          rejectionReason: reason,
        })
        .then(() => {
          this.$message.success(this.$t("success.punitionValid"));
          this.data = this.data.map((elem) => {
            if (elem._id == id) {
              elem.validated = value;
              elem.reviewDate = date;
              elem.rejectionReason = reason;
            }
            return elem;
          });
        })
        .catch(() => {})
        .finally(() => {
          this.cancelReject();
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.editable-row-operations a {
  margin-right: 8px;
}

.switch {
  float: right;
  margin-right: 15px;
}

.bottom {
  margin-top: 7px;
}

label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}

.span {
  /* display: flex;  */
  flex-direction: row;
}

.space {
  margin-left: 200px;
}

.inp {
  margin-left: 0px;
}

.clearfix {
  float: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.card >>> .ant-table-tbody tr {
  cursor: pointer !important;
}
</style>
